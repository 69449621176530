.order-payment__request-bill-modal {
  .ant-form .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    color: #1a1a1a;
    content: ":";
    display: inline-block;
    font-family: SimSun,sans-serif;
    font-size: 12px;
    line-height: 1;
    margin-right: 0;
    position: absolute;
    right: 17px;
  }

  .ant-form .ant-form-item-required:after {
    color: #ff4d4f;
    content: "*";
    display: inline-block;
    font-family: SimSun,sans-serif;
    font-size: 12px;
    line-height: 1;
    margin-left: 8px;
  }

  .order-payment__request-bill-modal__form {
    min-height: 176px;
  }
}

.payment-log-modal {
  font-size: 12px;

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close {
    top: -3px;

    i {
      font-weight: bold;
    }
  }

  .ant-modal-title {
    border-bottom: 1px solid #C7C7C7;
    padding-bottom: 8px;
    .txt-title {
      color: #fbb739;
    }
  }

  .text-gray {
    color: #BDBDBD;
  }

  .text-normal {
    color: #1A1A1A;
  }

  .text-medium {
    color: #1A1A1A;
    font-weight: 500;
  }

  .txt-size-14 {
    font-size: 14px;
  }
  .PENDING {
    color: #FFA634
  }

  .PAID {
    color: #09B8AF
  }

  .REJECTED {
    color: #FF4559
  }

  .WAITING {
    color: #7D7E7E
  }

  .PROMOTED {
    color: #09B8AF
  }

  .UNPROMOTED {
    color: #7D7E7E;
  }

  .NOTIFIED {
    color: #008DF9;
  }
}
