@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
body {
	font-family: 'Roboto', sans-serif;
	color: #1a1a1a;
}

.text {
	font-size: 12px;
	line-height: 20px;
	&-13 {
		font-size: 13px;
	}
	&-sm {
		font-size: 10px;
		line-height: 12px;
	}
	&-reg {
		font-size: 14px;
		line-height: 22px;
	}
	&-md {
		font-size: 16px;
		line-height: 24px;
	}
	&-lg {
		font-size: 20px;
		line-height: 28px;
	}

	&-nowrap {
		white-space: nowrap;
	}

	&-gray {
		color: $color-app-gray2;
		&-1 {
			color: $color-app-gray1;
		}
	}

	&-violet {
		color: $color-app-violet !important;
	}

	&-red {
		color: $color-app-red;
	}
	&-orange {
		color: $color-app-orange;
	}
	&-white {
		color: white;
	}
	&-base {
		color: $color-app-base;
	}

	&-500 {
		font-weight: 500;
	}

	&-700 {
		font-weight: 700;
	}
}

.item {
	&-center {
		align-items: center;
	}
}
