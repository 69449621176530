.package-order-list {
  background-color: #ffffff;

  .package-order-header {
    padding: 16px 12px;

    button {
      max-width: 100%;
      align-items: center;
      justify-content: center;
      padding: 6px 10px;
    }
  }

  .package-list-container {
    min-width: 1200px;

  }

  .package-order-header-box {
    align-items: center;
    //padding: 0 12px 0 0;
    margin: 0
  }

  @media (max-width: 575px) {
    .package-order-header-box {
      align-items: center;
      //padding: 0 12px 0 0;
      margin-bottom: 8px;
    }
  }


  .package-order-item {
    border: 1px solid transparent;
    border-bottom: 1px solid #EBEBEB;;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;

    &:hover {
      background-color: #fff7f7;
      cursor: pointer;
    }

    .ant-col {
      padding: 0 4px;
    }

    .item-logo {
      align-self: center;
    }

    .item-info {
      width: 100%;
      display: flex;
      flex-direction: column;

      & div {
        width: 100%;
        display: flex;
      }
    }

    .provide-content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 128px;
    }

    .entry-content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 52px;
    }

    .package-order-promote-status {
      font-size: 12px;
      justify-content: center;
      align-items: center;
      //padding: 0 14px;
      .ant-select-selector {
        height: 22px;
        line-height: 22px;
        align-items: center;
      }

      .ant-select-selector-item {
        height: 22px;
        line-height: 22px;

      }
    }
  }
}

.entry-popup-container {

  .ant-modal-header {
    .ant-modal-title {
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 16px;
    }

    div span {
      white-space: nowrap;
    }

    .divider {
      flex: 1;
    }

    .ant-divider-horizontal {
      clear: both;
      width: 100%;
      margin-top: 10px;
      margin-left: 10px;
      border-top: 1px solid #EBEBEB;
    }
  }

  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
  }

  .ant-modal-body {
    //margin-top: 10px;
    padding: 4px 12px 12px 12px;

    div {
      white-space: nowrap;
      line-height: 32px;
    }
  }

  .ant-modal-footer {
    border: 1px solid transparent !important;
    padding: 8px 10px;

    .submit {
      padding: 5px 16px;
      background: #CD5E77;
      border-radius: 4px;
      color: #ffffff;

      &:hover {
        box-shadow: 0 0 0 3px $primary-color-hover;
      }

      &:disabled {
        background: #F5F5F5;
        color: #BDBDBD;
      }
    }

    .cancel {
      border-color: #C7C7C7;
    }

    .cancel:hover {
      border-color: #C7C7C7;
      color: #1A1A1A;
    }
  }
}

.package-filter__form {
  flex-basis: 0;
}

.package-order__filter {
  padding-top: 8px;
}

@media (max-width: 375px) {
  .collapse-expand {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .package-filter__form.ant-form-horizontal .ant-form-item-label {
    flex-basis: 72px!important;
  }
}
