.access-denied {
  height: 100%;
  .body {
    height: 100%;
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .content {
        width: 350px;
        text-align: center;
      }
      .title {
        font-size: 2rem;
        text-align: center;
      }
      .message {
        font-size: 1rem;
        text-align: center;
      }
    }
  }
}