.status {
    border-radius: 25px;
    font-size: app-font-sizes(reg);
    line-height: 22px;
    padding: 2px 12px;
    color: white;
}

.badge {
    // flex items-center justify-center robotoregular line-h-20 txt-color-black px-8 pd-t-1 w-23 mg-bt-2 border-radius20
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    border-radius: 20px;
    margin-bottom: 2px;
    background-color: $bg-color-gray-tab;
    color: $color-app-base;
    &-active {
        background-color: $color-app-violet;
        color: white;
    }
}

.item-link {
    color: $color-app-base;
    font-size: 14px;
    font-weight: 500;
    &:hover {
        color: $color-app-violet;
    }
}

.payment {
    &.ant-select-open .ant-select-selection-item {
        color: white !important;
    }
    & .ant-select-selector {
        background-color: $color-app-orange !important;
        border-color: $color-app-orange !important;
        color: white;
        border-radius: 20px !important;
        padding: 0 auto !important;
        line-height: 20px !important;
        box-shadow: none !important;
        font-size: 12px !important;
        height: 22px !important;
        display: flex;
        align-items: center;
    }
    &-success {
        & .ant-select-selector {
            background-color: $color-app-blue !important;
            border-color: $color-app-blue !important;
        }
    }
    &-waiting-confirm {
        & .ant-select-selector {
            background-color: $color-app-orange-1 !important;
            border-color: $color-app-orange-1 !important;
        }
    }
    &-failure {
        & .ant-select-selector {
            background-color: $color-app-6 !important;
            border-color: $color-app-6 !important;
        }
    }
    &-waiting-payment {
        & .ant-select-selector {
            background-color: $color-app-orange-1 !important;
            border-color: $color-app-orange-1 !important;
        }
    }
    &-failure {
        & .ant-select-selector {
            background-color: $color-app-6 !important;
            border-color: $color-app-6 !important;
        }
    }
}