.ant-modal-root {
  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-close-x {
    width: 48px;
    height: 48px;
    color: #1A1A1A;
  }
  
  .ant-modal-header {
    border-radius: 4px 4px 0 0;
    padding: 12px 24px 0 12px;
    border-bottom: 0;
  }

  .ant-modal-body {
    padding: 12px;
    padding-top: 8px;
  }
}
