.provider-selection {
	& .ant-select-selector {
		border-radius: 4px !important;
	}
}

.phone {
	position: relative;
	.messages-validate {
		position: absolute;
		bottom: -25px;
		left: 0;
	}
}

@include xs {
	.customer-detail {
		& .box-info {
			flex-direction: column;
			& .avatar {
				flex-direction: row;
				align-items: center;
				justify-content: space-around;
			}
		}
	}
}
