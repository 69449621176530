.main-body {
	height: 100%;
	background-color: #f4f6f8;
}

.main-layout {
	.sidebar {
		min-height: 100vh;
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.07);
		position: fixed;
		top: 0;
		z-index: 998;
		border-right: 1px solid #f0f0f0;
		
		&--expand {
			max-width: 300px !important;
			width: auto !important;
			.sidebar-menu-container {
				.sidebar-menu-item {
					&-name {
						&--parent {
							justify-content: flex-start !important;
						}
					}
				}
			}
		}

		.logo {
			width: 100%;
			padding: 20px 10px 10px 25px;
			height: 64px;
			box-sizing: border-box;
			background-color: #002040;

			img {
				width: 80px;
				height: 25px;
			}

			&.collapsed {
				img {
					width: 27px;
					height: 25px;
				}
			}
		}

		.sidebar-menu-container {
			.sidebar-menu-item {
				&-link {
					display: flex;
					align-items: center;
				}
				&-icon {
					color: $primary-color;
					margin-right: 10px;
				}
				&-name {
					font-size: 16px;
					&--parent {
						font-size: 12px;
						font-weight: 500;
						color: $menu-item-parent-color;
						display: flex;
						justify-content: center;
					}
				}
			}
			.ant-menu-submenu-arrow {
				display: none;
			}
		}

		.ant-menu-item-selected {
			color: #000;
		}

		&__trigger {
			&-top {
				width: 100%;
				display: flex;
				padding: 15px 20px;
				justify-content: space-between;
				align-items: center;
				position: relative;
				.trigger-icon {
					color: $primary-color;
					font-weight: bold;
					font-size: 18px;
					cursor: pointer;
				}
				.trigger-logo {
					height: 28px;
				}

				&.closable {
					display: flex;
					align-items: center;
					& .anticon-close {
						position: absolute;
						top: 25px;
						right: 8px;
						color: #000;

						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}

		.ant-layout-sider-trigger {
			background-color: #fff;
			.trigger-icon {
				color: $primary-color;
			}
		}

		.ant-menu-sub {
			background-color: #fff;
		}
	}

	.header {
		background-color: #fff;
		padding-left: 72px;
		padding-right: 0;
		position: sticky;
		top: 0;
		z-index: 9998;
		// box-shadow: 0 8px 16px rgba(0, 0, 0, 0.07);

		&.fixed {
			position: sticky;
			top: 0;
			line-height: unset;
			z-index: 997;
			@include xs {
				width: 100%;
			}
		}

		.user {
			@include xxs-min {
				.ant-avatar-lg {
					width: 28px;
					height: 28px;
				}
			}
		}

		.header-inner {
			position: relative;
			height: 64px;
			padding: 0;
			width: 100%;

			&-inner {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 12px 0 0;
				@include xxs-min {
					padding: 0 12px;
				}
				& .anticon-menu {
					color: #000;
				}
				.title {
					font-size: 16px;
					line-height: 24px;

					@include xxs-min {
						font-size: 14px;
					}
				}
			}
		}
		.left__header {
			@include xs {
				margin-left: 0;
			}
		}
		.trigger {
			font-size: 22px;
			cursor: pointer;
		}

		.right-header {
			display: flex;
			justify-content: space-around;
			align-items: center;
			.item {
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				// gap: 16px;
				.user__actions {
					margin-right: 10px;
					@include md {
						margin-right: 16px;
					}
				}
			}
			.notify {
				display: flex;

				.ant-scroll-number-only-unit {
					color: white;
				}
			}
		}
	}

	.ant-layout-content {
		margin-left: 72px;
		@include xs {
			margin-left: 0;
		}
	}

	.main-content {
		margin: 12px 12px 12px 66px;
		.bread-crumb-container {
			background-color: #fff;
			padding: 15px 30px 12px;
		}

		.white-box {
			background-color: #fff;
			padding: 20px;
			border-radius: 4px;

			&--padding-15px {
				padding: 15px;
			}

			&--no-margin {
				margin: 0;
			}
		}

		.tab-pane-inner-box {
			padding: 0 20px 20px 20px;
		}

		.h-line {
			border-bottom: 1px solid #ccc;
		}

		.loading-container {
			text-align: center;
			padding: 30px;
		}
	}
	.ant-menu-item-selected {
		background-color: $primary-color-hover;
		.ant-menu-title-content {
			.anticon {
				color: #fff;
			}
			.sidebar-item {
				a {
					color: #fff;
				}
			}
		}
	}

	.footer {
		background: #fff;
		text-align: center;
		padding: 16px 24px 16px 24px;
		font-size: 12px;
		line-height: 22px;
	}

	.ant-layout {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		background-color: $color-app-violet-light;
	}
}
.toggle-mobile {
	@include sm {
		display: none;
	}
}
.sidebar-popup-container {
	.sidebar-menu-item-icon {
		color: $primary-color;
	}
	.sidebar-menu-item-link {
		padding-left: 9px;
	}
}

.ant-layout-sider {
	background-color: #fff;
}

.ant-menu-inline-collapsed-tooltip {
	.ant-tooltip-inner {
		width: auto;
	}
}

.menu-item-tooltip {
	z-index: 10000;
	.ant-tooltip-inner {
		background-color: $primary-color;
		border-radius: 4px;
	}
	.ant-tooltip-arrow-content::before {
		background: $primary-color;
	}
}

.ant-layout-sider-collapsed {
	.ant-menu-item {
		.sidebar-item {
			display: none;
		}
	}
}

@include xs {
	.main-layout {
		.header {
			.right-header {
				.notify,
				.line-1 {
					display: none;
				}
				.user {
					position: relative;
					&__actions {
						display: none;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 992px) {
	.main-layout .main-content {
		margin-left: 66px;
	}
	.main-layout {
		& .header {
			padding-left: 0px;
			&-inner-inner {
				padding: 12px !important;
				margin-left: 56px;
			}
		}
		
	}
}

@media screen and (max-width: 767px) {
	.main-layout .main-content {
		margin-left: 12px;
	}

	.main-layout {
		& .header-inner-inner {
			margin-left: 0;
		}
	}
}



