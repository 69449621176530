.preference {
	display: flex;
	&-title {
		font-size: 16px;
	}
	&-content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: space-between;
		box-shadow: 0px 2px 14px #e6e6e6;
		background-color: white;
		padding: 24px;
		border-radius: 4px;
		&__switch {
			display: flex;
			flex-direction: column;
			& .label {
				margin-right: 12px;
				font-size: 14px;
				line-height: 22px;
			}

			&-group {
				width: 200px;
			}

			&-item {
				display: flex;
				margin-bottom: 12px;
				justify-content: space-between;
				align-items: center;
				& .label {
					margin-right: 40px;
					font-weight: 500;
					font-size: 14px;
					line-height: 22px;
				}
			}

			& .ant-btn {
				color: white;
				font-size: 14px;
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}

		&__heading {
			display: flex;
			align-items: center;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			color: #1a1a1a;
			margin-bottom: 24px;
			& .label {
				margin-right: 6px;
			}

			& .icon {
				font-size: 10px;
				cursor: pointer;
			}
		}
	}
	&-confirm {
		& .ant-modal-close {
			top: -10px;
			right: -8px;
		}
		& .ant-modal-confirm-body {
			& .ant-modal-confirm-content {
				font-size: 14px;
				line-height: 22px;
			}
			& .ant-modal-confirm-title {
				font-size: 16px;
				line-height: 24px;
			}
		}
		& .ant-modal-confirm-btns {
			display: flex;
			justify-content: flex-end;
		}
	}

	&-navigation {
		display: flex;
		flex-direction: column;
		box-shadow: 0px 2px 14px #e6e6e6;
		background-color: white;
		margin-right: 12px;
		padding: 24px;
		border-radius: 4px;
		flex-basis: 30%;

		& > div:not(:last-of-type) {
			margin-bottom: 12px;
		}

		&__item {
			display: flex;
			align-items: center;
			border-radius: 6px;
			padding: 12px;
			cursor: pointer;
			& .icon {
				margin-right: 6px;
				display: flex;
				align-items: center;
				color: #707070;
				font-size: 14px;
			}
			& .label {
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				color: #707070;
			}

			&.active {
				background: #FFE7E7;
				& .label, & .icon {
					color: #cd5e77;
					// white-space: nowrap;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
 .preference {
	flex-direction: column;
	&-navigation {
		margin-right: 0;
		margin-bottom: 12px;
	}
 }
}