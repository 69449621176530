.btn {
  color: $color-app-base;
  box-shadow: none;

  &.ant-btn:not([disabled]) {
    &:active {
      box-shadow: none;
    }
  }

  &-primary {
    &.ant-btn:not([disabled]) {
      color: #fff;
      border-radius: 4px;

      &:hover {
        box-shadow: 0 0 0 3px $color-app-violet-light1;
        background-color: $color-app-violet;
      }
    }
  }
}

.ant-btn {
  border-radius: 4px;

  &.ant-btn-primary {

    &:not([disabled]) {
      &:hover {
        box-shadow: 0 0 0 3px $color-app-violet-light1;
        background-color: $color-app-violet;
      }
    }
    &:disabled {
      border:1px solid #c7c7c7;
      //&:hover {
      //  border:1px solid transparent;
      //}
    }
  }

  &.ant-btn-ghost {
    &:not([disabled]) {
      &:hover {
        background-color: #707070;
        color: #ffffff;
        border: 1px solid transparent;
      }
    }
  }
}
