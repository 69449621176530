.error-page {
	min-height: calc(100vh - 142px);
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.headline {
		float: left;
		font-size: 100px;
		font-weight: 300;
	}

	.error-content {
		// margin-left: 190px;
		text-align: center;
		h3 {
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			color: $primary-color;
		}
		p {
			font-size: 14px;
			line-height: 22px;
			color: $primary-color;
		}
	}
}
