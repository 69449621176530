.splash-container {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .splash-text {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }
}