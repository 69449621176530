.a-table {
	&--responsive {
		width: 100%;
		overflow: auto;
	}
	&--font-small {
		table tbody {
			font-size: 12px;
		}
	}
	table {
		font-size: 12px;

		@media only screen and (min-width: 1200px) {
			font-size: 12px;
		}

		@media only screen and (min-width: 1400px) {
			font-size: 12px;
		}
	}
}

.main-table {

	& .ant-table-thead > tr > th {
		font-size: 14px;
		line-height: 22px;
		padding: 5px 12px;
		background-color: $table-header-bg;
		border-bottom: none;
		color: $color-app-base;
		background-color: $bg-color-gray-tab;
	}

	& .ant-table-title {
		padding: 0 !important;
		padding-bottom: 12px !important;
	}

	& .ant-table-tbody > tr > td {
		padding: 8px 12px;
		vertical-align: top;
		color: $color-app-base;
		& .ant-typography {
			color: $color-app-base;
			&-edit-content {
				left: 0;
			}
		}
	}
	
	
	&_middle {
		& .ant-table-tbody > tr > td {
			vertical-align: middle;
		}
	}

	&_small {
		& .ant-table-thead > tr > th {
		font-size: 12px;
		line-height: 20px;
		}
	}

	&__border-head-none {
		& .ant-table-thead > tr > th::before {
			display: none;
		}
	}
}

.pagination-separate {
	display: flex;
	margin-bottom: 12px;
	.ant-pagination-item {
		font-family: 'Roboto', sans-serif;
		& > a {
			font-size: app-font-sizes(reg);
		}
		&-active {
			background-color: $color-app-violet;
			& > a {
				color: white;
			}
		}
	}
	.ant-pagination-options .ant-select-selection-item{
		font-size: app-font-sizes(reg);
		color: #707070;
	}
	.ant-pagination-total-text {
		margin-right: auto;
		padding-left: 12px;
		padding-right: 12px;
	}
}


// @media screen and (max-width: 1280px) {
// 	.main-tabe {
// 		& .ant-table-content {
// 			overflow: scroll !important;
// 		}
// 	}
// }

