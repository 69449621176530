/* Color*/
$color-base: #666;
$color-secondary: #222;
$color-text: #666;
$color-text-secondary: #888;
$color-text-heading: #222;
$color-link: #3498db;
$color-bg-footer: #282828;

/* Variable Template, App Layout */

$color-app-base: #1a1a1a;
$color-app-primary: #2a9f9c;
$color-app-primary-m: #22817e;
$color-app-tertiary: #e2fbfa;
$color-app-secondary: #f4f6f8;
$color-app-border: #34c4c0;
$color-app-disabled: #bdbdbd;
$color-app-base-input: #d9d9d9;
$color-app-link: #008df9;
$color-app-modal-border: #c7c7c7;
$color-app-placeholder: #bdbdbd;
$color-app-heading-sub: #707070;
$color-app-white: #fff;
$color-app-gray: #C7C7C7;
$color-app-gray1: #BDBDBD;
$color-app-gray2: #707070;
$color-app-gray3: #EBEBEB;
$color-app-gray4: #F5F5F5;
$color-app-gray5: #E5E5E5;
$color-app-orange: #FFA634;
$color-app-red: #FF4559;
$color-app-6: #A6A6A6;
$color-app-blue: #4674C1;
$color-app-orange-1: #F4B171;
$color-app-black: #2b2424;
$color-app-pink: #FFE4E6;
//**************************

$darkSkyBlue: #3498db;
$line: #cbcacf;
$title: #333;
$jadeGreen: #27ae60;
$brownishGrey: #666;
$bloodOrange: #ff4400;
$tomato: #e74c3c;
$btn: #adadad;

/*//Fonts-size*/
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 32px;

/*//Url base*/
$url-img: '../../images';

/*//Breakpoint*/
$bp-xs: 320px;
$bp-mobile: 320px;

$bp-s: 480px;
$bp-sm: 768px;
$bp-tablet: 768px;

$bp-m: 992px;
$bp-l: 1200px;
$bp-xl: 1440px;

/*// Contain floats / clearfix*/

%clearfix {
    *zoom: 1;
    &:before,
    &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }
}

/* New style 2018-03-08 */

/* Variable Template, App Layout */
$color-app-base: #1a1a1a;
$color-app-primary: #2a9f9c;
$color-app-primary-m: #22817e;
$color-app-tertiary: #e2fbfa;
$color-app-secondary: #f4f6f8;
$color-app-border: #34c4c0;
$color-app-disabled: #bdbdbd;
$color-app-base-input: #d9d9d9;
$color-app-link: #008df9;
$color-app-modal-border: #c7c7c7;
$color-app-placeholder: #bdbdbd;
$color-app-heading-sub: #707070;
$color-app-violet: #CD5E77;
$color-app-violet-light: #FFF7F7;
$color-app-violet-light1: #f4dcdf;
$color-app-violet-light2: #FFE4E6;
$color-app-violet-light3: #EBA7AC;
$base-color: #00abf3;
$base-text-color: #fff;
$color-app-green: #09B8AF;

$font-weights: (
    'thin': 100,
    'light': 300,
    'regular': 400,
    'medium': 500,
);

@function font-weights($style) {
    @return map-get($font-weights, $style);
}

$app-font-sizes: (
    'sm': 12px,
    'reg': 14px,
    'md': 16px,
    'lg': 24px,
    'heading': 20px,
);

@function app-font-sizes($style) {
    @return map-get($app-font-sizes, $style);
}

$app-button-colors: (
    'selected': $color-app-tertiary,
    'clicked': $color-app-secondary,
    'hover': $color-app-primary-m,
);

@function app-button-colors($style) {
    @return map-get($app-button-colors, $style);
}

$app-text-colors: (
    'base': $color-app-base,
    'primary': $color-app-primary,
    'secondary': $color-app-placeholder,
    'desc': $color-app-heading-sub,
    'badge': $color-app-tertiary,
);

@function app-text-colors($style) {
    @return map-get($app-text-colors, $style);
}
$primary-color: #CD5E77;
$primary-color-hover: #FFD3D8;
$menu-item-parent-color: #BDBDBD;
$box-shadow-color: #ced1f3;
$box-shadow-color1: #E6E6E6;
$box-shadow-color: #ced1f3;


//table 
$table-header-bg: #F2F2F2;
$bg-color-gray-tab: #F4F6F8;
